import OrderCard from "../components/OrderCard";
import appImages from "../constants/images";

export const sortByStore = (bagData: any[]) => {
  bagData.sort((a, b) =>
    a?.storeInfo?.storeDetail?.name > b?.storeInfo?.storeDetail?.name
      ? 1
      : a?.storeInfo?.storeDetail?.name < b?.storeInfo?.storeDetail?.name
      ? -1
      : 0
  );
  let currentStore: any = null;
  const renderArray = bagData.map((_v, _i) => {
    if (_v?.storeInfo?.storeDetail?.name !== currentStore) {
      currentStore = _v?.storeInfo?.storeDetail?.name;

      return (
        <>
          <div
            className="delivery-method-contain"
            key={_v?.storeInfo?.storeDetail?.name}
          >
            <div>{_v?.storeInfo?.storeDetail?.name}</div>
          </div>
          <div key={_i}>
            <OrderCard
              productInfo={_v?.productInfo}
              storeInfo={_v?.storeInfo}
              prefrence={_v?.sortingType}
              key={_i}
            />
          </div>
        </>
      );
    }

    return (
      <div key={_i}>
        <OrderCard
          productInfo={_v?.productInfo}
          storeInfo={_v?.storeInfo}
          prefrence={_v?.sortingType}
          key={_i}
        />
      </div>
    );
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {renderArray}
    </div>
  );
};

export const sortByDelivery = (bagData: any[]) => {
  const customSortOrder = ["pickup", "courier", "shipping"];
  const renderArray: any[] = [];
  bagData.sort(
    (a, b) =>
      customSortOrder.indexOf(a?.storeInfo?.type) -
      customSortOrder.indexOf(b?.storeInfo?.type)
  );

  let currentMethodType: any = null;
  bagData.map((_v, _i) => {
    if (_v?.storeInfo?.type !== currentMethodType) {
      currentMethodType = _v?.storeInfo?.type;
      renderArray.push(
        <>
          <div
            className="delivery-method-contain"
            style={{ display: bagData?.length > 1 ? "" : "none" }}
          >
            <img
              style={{ height: "16px" }}
              src={
                _v?.storeInfo?.type === "pickup"
                  ? appImages.PICKUP_VECTOR_ICON
                  : _v?.storeInfo?.type === "courier"
                  ? appImages.COURIER_VECTOR_ICON
                  : appImages.SIPPING_VECTOR_ICON
              }
            />
            <div>{_v?.storeInfo?.type}</div>
          </div>
        </>
      );
    }
    renderArray.push(
      <div key={_i}>
        <OrderCard
          productInfo={_v?.productInfo}
          storeInfo={_v?.storeInfo}
          prefrence={_v?.sortingType}
          key={_i}
        />
      </div>
    );
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {renderArray}
    </div>
  );
};
