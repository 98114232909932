import React from "react";
import appImages from "../constants/images";
import { priceConverter } from "../helpers/utilHelpers";
interface Iprops {
  totalAmount: number | string;
}
function BagPanelBottom({ totalAmount }: Iprops) {
  return (
    <div className="totalToPayCompund bag-panel-bottom">
      <div className="totalToPay">
        <div
          className="dashlineTextinside"
          style={{ margin: "0px 0px 16px 0px" }}
        >
          <div className="sperateBorderclass"></div>
          <p className="dashedText">
            <span className="orContinueText">Total to pay</span>
          </p>
        </div>
        <div className="maxiMumAmoutn" style={{ height: "100%" }}>
          <div className="finalTotalAmount">
            {priceConverter(totalAmount, "de-DE")} kr
          </div>
          <div className="singlePrBtnsShareBag">
            <div
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "20px", width: "20px" }}
                src={appImages.NAVIGATION_ICON}
              />
            </div>
            <div className="fixws shareBagifn">Optimize bag</div>
            <div
              className="fixws prodeedCheckout"
              //   onClick={() => {
              //     if (bagData?.length > 1) {
              //       quickCheckout("multi", totalAmount) ||
              //         totalPrice(bagData);
              //     } else {
              //       quickCheckout("single", productAmount + deliveryCharge);
              //     }
              //   }}
            >
              Checkout
            </div>
          </div>
        </div>

        <div className="optimize-terms-cond">
          By clicking “Optimize bag” you will be able to calculate the best way{" "}
          <br />
          for you to buy all products.
        </div>
      </div>
    </div>
  );
}

export default BagPanelBottom;
