import { BOTAG_API, CREATE_ORDER } from "../../api/Endpoints";
import { GetBoTag } from "../../interfaces/payload";
import { boTheBotApi } from "../baseApi";

export const OrderApiEndpoints = boTheBotApi.injectEndpoints({
  endpoints: (builder) => ({
    createNewOrder: builder.mutation({
      query: (body) => ({
        url: `${CREATE_ORDER}${process.env.REACT_APP_BOTHEBOT_API_CODE}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Order"],
    }),
    getBoTagData: builder.mutation<any, GetBoTag>({
      query: (body) => ({
        url: `${BOTAG_API}${process.env.REACT_APP_BOTHEBOT_API_CODE}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const { useCreateNewOrderMutation, useGetBoTagDataMutation } =
  OrderApiEndpoints;
