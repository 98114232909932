import { useEffect, useState } from "react";

import { useGetBoImagePublisherDataMutation } from "../store/product/ProductApi";

interface Args {
  onSuccess?: () => void;
  onOrderSuccess?: () => void;
}
export const useProduct = ({ onSuccess }: Args) => {
  const [error, setError] = useState<string>("");
  const [getProduct, { isLoading, isSuccess, isError, data, isUninitialized }] =
    useGetBoImagePublisherDataMutation();

  useEffect(() => {
    if (isLoading) {
      setError("");
      return;
    }

    if (isSuccess) {
      setError("");
      onSuccess?.();
    } else if (isError) {
      const errMessage = "Incorrect email or password. Try again.";
      setError(errMessage);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handleProduct = (payload: any) => {
    getProduct(payload);
  };

  return {
    handleProduct,
    data,
    isLoading,
    isUninitialized,
    isError,
  };
};
