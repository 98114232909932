import PhoneInput from "react-phone-number-input";
import InputMask from "react-input-mask";
import "../styles/customerInfo.css";
import { FC, useEffect, useState } from "react";
import { CustomerInfoProps } from "../interfaces/props";
interface Iprops {
  setCustomerData: (e: CustomerInfoProps) => void;
  isLast: boolean;
  isFirst: boolean;
  isEmail: boolean;
  isPhone: boolean;
}
const CustomerInfo: FC<Iprops> = ({
  setCustomerData,
  isLast = true,
  isFirst = true,
  isEmail = true,
  isPhone = true,
}) => {
  const [fName, setFName] = useState<string>("");
  const [lName, setLName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string | number>("");
  const [value, setValue] = useState<string | number>("+47");
  const handleCustomerFName = (e: any) => {
    setFName(e.target.value);
  };
  const handleCustomerLName = (e: any) => {
    setLName(e.target.value);
  };
  const handleCustomerEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handleCustomerMobile = (e: any) => {
    setMobile(e.target.value);
  };
  const onChangePhone = (e: any) => {
    setValue(e);
  };
  useEffect(() => {
    if (fName && lName && email && value && mobile) {
      const data: CustomerInfoProps = {
        firstName: fName,
        lastName: lName,
        email: email,
        mobileNumber: value + `${mobile}`,
      };
      setCustomerData(data);
    }
  }, [fName, lName, email, value, mobile]);

  return (
    <div className="customerDetailsIn">
      <h4 className="subHeadingEighteen">
        <span className="headingLines">Info</span>
      </h4>
      <div className="customerInfoForm">
        <div className="customrow">
          <div className="halfDiv">
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 20,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",
                  display: !isFirst && !fName ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <input
                type="text"
                className="form-control"
                style={{
                  border: !isFirst && !fName ? "0.5px solid red" : "",
                }}
                placeholder="First Name"
                onChange={handleCustomerFName}
                value={fName}
              />
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 20,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",

                  display: !isLast && !lName ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <input
                type="text"
                className="form-control"
                style={{
                  border: !isLast && !lName ? "0.5px solid red" : "",
                }}
                placeholder="Last Name"
                onChange={handleCustomerLName}
                value={lName}
              />
            </div>
          </div>
          <div className="fullDiv">
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 14,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",
                  display: !isEmail && !email ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <input
                type="text"
                className="form-control"
                style={{
                  border: !isEmail && !email ? "0.5px solid red" : "",
                }}
                placeholder="Email"
                onChange={handleCustomerEmail}
                value={email}
              />
            </div>
          </div>
          <div className="fullDiv">
            <div
              className="form-group phoneNumberSlot"
              style={{ position: "relative" }}
            >
              <label
                style={{
                  position: "absolute",
                  right: 14,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",
                  display: !isPhone && !mobile ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <div className="phoContain">
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  value={value as any}
                  onChange={(e) => onChangePhone(e)}
                  defaultCountry="NO"
                />
                <span className="numberValues" style={{ color: "black" }}>
                  {value}
                </span>
              </div>
              <InputMask
                mask="999 999 9999"
                className="form-control mobileEnter"
                style={{
                  border: !isPhone && !mobile ? "0.5px solid red" : "",
                }}
                value={mobile}
                onChange={handleCustomerMobile}
                placeholder="Phone Number"
                maskChar=""
              ></InputMask>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
