import { ArrowBack } from "@mui/icons-material";
import appImages from "../constants/images";
import "../styles/navbar.css";
import { useState } from "react";
import { BagPanel } from "../panels/BagPanel";
import { getSingleCacheData } from "../helpers/utilHelpers";
export const Navbar = () => {
  const [showBag, setShowBag] = useState(false);
  const [bagData, setBagData] = useState([]);
  const openBag = async () => {
    setShowBag(!showBag);
    let getData = await getSingleCacheData("myBag", window.location.origin);

    if (getData) {
      setBagData(getData);
    } else {
      setBagData([]);
    }
  };

  return (
    <>
      <div className="navbar">
        <div
          className="right-side"
          onClick={() => {
            window.parent.postMessage("hideButton", "*");
          }}
        >
          <ArrowBack fontSize="small" />
          <span className="locationcloseIcon">Close</span>
        </div>
        <div className="left-side">
          <img
            className="bag-icon"
            src={appImages.BAG_ICON}
            onClick={openBag}
          />
          <img src={appImages.LOGOUT_ICON} />
        </div>
      </div>
      {showBag ? <BagPanel closeEmptyBag={openBag} bagData={bagData} /> : null}
    </>
  );
};
