import { ArrowBack } from "@mui/icons-material";
import "../styles/bagPanel.css";
import { FC, useState } from "react";
import OrderCard from "../components/OrderCard";
import { sortByDelivery, sortByStore } from "../helpers/sortHelpers";
import BagPanelBottom from "../components/BagPanelBottom";
import { totalPrice } from "../helpers/utilHelpers";
interface Iprops {
  closeEmptyBag?: () => void;
  bagData: any[];
}
export const BagPanel: FC<Iprops> = ({ closeEmptyBag, bagData }) => {
  const [sort, setSort] = useState("Delivery");
  const changeSort = () => {
    if (sort === "Delivery") {
      setSort("Store");
    } else {
      setSort("Delivery");
    }
  };

  return (
    <div className="checkNew bagisOpen">
      <div className="bag-nav">
        <div
          className="singleCheckoutheadiDiv"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="right-side">
            <ArrowBack
              fontSize="small"
              onClick={() => {
                window.parent.postMessage("hideButton", "*");
              }}
            />
            <span className="locationcloseIcon">Close</span>
            <span className="bagNumberCount">Your bag</span>
          </div>
          <div className="right-nav-bag">
            <div
              className="sort-btn"
              onClick={changeSort}
              style={{ display: bagData?.length > 1 ? "" : "none" }}
            >
              Sort by: {sort}
            </div>
            <div
              className="moreInfoClose makeItRedbg rightBg"
              onClick={closeEmptyBag}
            >
              Cancel
            </div>
          </div>
        </div>
        <div style={{ height: "0.5px", backgroundColor: "#BAE7DC" }}></div>
      </div>
      <div
        className="bag-data-continer"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          height: "calc(100vh - 223px)",
          paddingBottom: "10vh",
        }}
      >
        {sort === "Delivery" ? (
          <div style={{ width: "100%" }}>
            <div>{sortByDelivery(bagData)}</div>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <div>{sortByStore(bagData)}</div>
          </div>
        )}
      </div>
      <BagPanelBottom totalAmount={totalPrice(bagData)} />
    </div>
  );
};
