import React, { useEffect, useState, useRef, useCallback } from "react";
import { getDistance } from "geolib";
import axios from "axios";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  MarkerClusterer,
  LoadScript,
} from "@react-google-maps/api";
import appImages from "../constants/images";

import "../styles/map.css";
interface Iprops {
  coords?: any;
  storeCoords?: any;
  storeInfo?: any;
  isShow?: any;

  address?: any;
  allStoreData?: any;
}
export default function Map({
  coords,
  storeInfo,
  isShow,
  address,
  // allStoreData,
  storeCoords,
}: Iprops) {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const markers = [storeCoords, coords];

  const [mapRef, setMapRef] = useState();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY || "",
  });

  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(16);
  const [mapData, setMapData] = useState<any>("");
  const [loading, setLoading] = useState(true);
  const [coordPosition, setCoordPosition] = useState({ lat: 0, lng: 0 });
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setCoordPosition({ lat: res.data.latitude, lng: res.data.longitude });
  };
  useEffect(() => {
    getData();
  }, []);

  const onLoad = (map: any) => {
    if (address) {
      map.setZoom(16);
    } else {
      map.setZoom(16);
    }
    setMapData(map);
    setMapLoaded(true);
    // You can adjust the zoom level further if needed
    // setZoom(map.getZoom());
  };

  useEffect(() => {
    if (mapData && mapLoaded) {
      const bounds = new window.google.maps.LatLngBounds();

      bounds.extend(storeCoords);
      bounds.extend(coords);
      mapData.fitBounds(bounds);
      setZoom(mapData.getZoom());
    }
  }, [mapData, markers[1]]);

  useEffect(() => {
    if (
      address &&
      mapData &&
      !storeInfo?.storeDetail?.latitude &&
      !storeInfo?.storeDetail?.longitude
    ) {
    }
  }, [address]);

  const icon = {
    url: appImages.USER_LOC_ICON,
    scaledSize: { width: 30, height: 40 },
  };
  const storeIcon = {
    url: appImages.STORE_ICON,
    scaledSize: { width: 30, height: 40 },
  };

  return (
    <div className="mapandBlack">
      <div className="mapandBlack" style={{ height: "181px" }}>
        <GoogleMap
          zoom={zoom}
          ref={mapRef}
          onLoad={onLoad}
          center={coords}
          mapContainerStyle={containerStyle}
          clickableIcons={false}
          options={{
            draggable: true,
            fullscreenControl: false,
          }}
        >
          <Marker
            position={coords}
            draggable={false}
            visible={true}
            zIndex={9999}
            icon={icon as any}
          />
          {storeInfo?.storeDetail?.longitude &&
          storeInfo?.storeDetail?.latitude &&
          typeof storeInfo !== "undefined" ? (
            <Marker
              position={storeCoords}
              draggable={false}
              visible={true}
              zIndex={999}
              icon={storeIcon as any}
            />
          ) : (
            ""
          )}

          {/* {allStoreData.length &&
            allStoreData.map((_d: any, _p: any) => {
              return (
                <Marker
                  key={_p}
                  position={{ lat: _d?.lat, lng: _d?.lng }}
                  draggable={false}
                  visible={true}
                  zIndex={999}
                  icon={appImages.STORE_LOCATION_ICON}
                />
              );
            })} */}
          {/* <MarkerClusterer
            styles={[
              {
                url: appImages.CLUSTER,
                height: 53,
                width: 53,
              },
              {
                url: appImages.CLUSTER,
                height: 53,
                width: 53,
              },
              {
                url: appImages.CLUSTER,
                height: 53,
                width: 53,
              },
              {
                url: "/static/images/cluster/m4.png",
                height: 53,
                width: 53,
              },
              {
                url: appImages.CLUSTER,
                height: 53,
                width: 53,
              },
            ]}
          >
            {(clusterer) =>
              allStoreData?.map((_d: any, i: any) => (
                <Marker
                  key={i}
                  position={_d}
                  clusterer={clusterer}
                  icon={appImages.STORE_ICON}
                />
              ))
            }
          </MarkerClusterer> */}
        </GoogleMap>
      </div>
    </div>
  );
}
