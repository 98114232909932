import appImages from "../constants/images";
import { deliveryPrefrence, secondsToDhms } from "../helpers/utilHelpers";
import "../styles/orderCard.css";
interface Iprops {
  storeInfo: any;
  prefrence: string;
  productInfo: any;
}
function OrderCard({ storeInfo, prefrence, productInfo }: Iprops) {
  return (
    <div className="orderandTracking" style={{ display: "block" }}>
      <div className="orderProductDetails">
        <div className="productImageIcon">
          <img
            src={productInfo?.image?.URL}
            style={{ width: "41px", height: "54px", borderRadius: "5px" }}
          />
        </div>
        <div className="productDetLine">
          <div className="prodBrandName">
            {productInfo?.brandName || ""} -{" "}
            {productInfo?.type || productInfo?.name}
          </div>
          <div className="productMainName" title="Sirup Cap">
            {productInfo?.name}
          </div>
          <div className="checkDetailComponent">
            <span className="chekoutColorset">{productInfo?.color} -</span>
            <span className="makeseprateMargin"> {productInfo?.size} -</span> 1
          </div>
        </div>
        <div className="productInfoLines">
          <div className="itemMoneyPr">
            <img
              src={appImages.DELETE_ICON}
              style={{ width: "18px", height: "18px" }}
            />
          </div>
          <div className="itemPriceFinal-order">{storeInfo?.price} kr</div>
        </div>
      </div>
      <div className="sperateBorderclassName"></div>
      <div className="prefrence-container">
        <img
          className="active-icon checkout"
          src={
            prefrence === "fastest"
              ? appImages.SOLID_FAST
              : appImages.SOLID_CHEAP
          }
        />
        <span className="prefrence-text">
          {deliveryPrefrence(prefrence, storeInfo?.type)} /{" "}
          {storeInfo?.type === "pickup"
            ? storeInfo?.awayFrom
            : storeInfo?.type === "courier" && storeInfo?.cf !== "yes"
            ? storeInfo?.estimatedTime
            : // secondsToDhms(storeInfo?.cf?.Zones?.[0]?.courierTime)
              secondsToDhms(storeInfo?.cf?.Zones?.[0]?.shippingTime)}
        </span>
      </div>
      <div className="orderCorierCart">
        <div className="courierInfotruck">
          <div className="trackCourierInfo">
            {storeInfo?.type === "pickup" ? (
              <div className="courierNames">Pickup</div>
            ) : storeInfo?.type === "shipping" ? (
              <div className="courierNames">
                Shipping by{" "}
                <img
                  src={storeInfo?.cf?.logoURL}
                  style={{ marginLeft: "5px", height: "9px" }}
                />
              </div>
            ) : (
              <div className="courierNames">
                Courier by{" "}
                <img
                  src={storeInfo?.cf?.logoURL}
                  style={{ marginLeft: "5px", height: "14px" }}
                />
              </div>
            )}
            <div className="fromLocations">
              Store:{" "}
              <span className="pointLocation">
                {storeInfo?.storeDetail?.name}
              </span>
            </div>
            {storeInfo?.type === "pickup" ? (
              <div className="fromLocations">
                Address:{" "}
                <span className="pointLocation">
                  {storeInfo?.storeDetail?.address}
                </span>
              </div>
            ) : (
              <div className="fromLocations">
                Deliver to:{" "}
                <span className="pointLocation">
                  {storeInfo.customerAdress}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="changeCourier newPrice">
          <div
            className="itemMoneyPr"
            style={{
              visibility: storeInfo?.type === "pickup" ? "hidden" : "visible",
            }}
          >
            {storeInfo?.type === "courier"
              ? storeInfo?.deliveryCharges
              : // storeInfo?.cf?.Zones?.[0].courierPrice
              storeInfo?.cf?.countryFound
              ? storeInfo?.cf?.Zones?.[0].shippingPrice
              : storeInfo?.cf?.Zones?.[1].shippingPrice}{" "}
            kr
          </div>

          <div className="change-icon-text">
            <img
              src={appImages.CHANGE_ICON}
              className="change-icon"
              style={{ width: "10px", height: "9px" }}
            />
            <span className="change-text">Change</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderCard;
