import { useRef } from "react";
import "../styles/storeLocation.css";
import moment from "moment";
import appImages from "../constants/images";

interface Iprops {
  storeInfo?: any;
  selectedStore?: any;
  showMore?: any;
  closeShowMore?: any;
  changeLabel?: any;
  showStoreMoreInfo?: any;
  otherClick?: any;
}
function StoreLocation({
  storeInfo,
  selectedStore,
  showMore,
  closeShowMore,
  changeLabel,
  showStoreMoreInfo,
  otherClick,
}: Iprops) {
  const storeData = storeInfo?.storeDetail ? storeInfo?.storeDetail : "";

  const mapDivRef = useRef();
  let openHours = storeData?.openHours;
  let weekDayArary = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  let todayDay = moment().utcOffset("+02:00").format("ddd");
  let calcCurrentTime = "";
  let todayCurrentTime = moment().utcOffset("+02:00");

  calcCurrentTime = todayCurrentTime.format("HH:mm:ss");
  let TomorrowDay = moment().utcOffset("+02:00").add(1, "days").format("ddd");
  let todayOpenHours, tomorrowOpenHours, isOpen;
  if (weekDayArary.includes(todayDay)) {
    todayOpenHours =
      openHours?.Weekdays?.hourStart + " - " + openHours?.Weekdays?.hourEnd;
    let startTime = moment(openHours?.Weekdays?.hourStart, "HH:mm:ss");
    let endTime = moment(openHours?.Weekdays?.hourEnd, "HH:mm:ss");
    isOpen = moment(calcCurrentTime, "HH:mm:ss").isBetween(startTime, endTime);
  } else {
    if (todayDay == "Sat") {
      todayOpenHours = openHours?.Saturdays?.hourStart
        ? openHours?.Saturdays?.hourStart +
          " - " +
          openHours?.Saturdays?.hourEnd
        : "Closed";
      let startTime = openHours?.Saturdays?.hourStart
        ? moment(openHours?.Saturdays?.hourStart, "HH:mm:ss")
        : "";
      let endTime = openHours?.Saturdays?.hourEnd
        ? moment(openHours?.Saturdays?.hourEnd, "HH:mm:ss")
        : "";
      isOpen =
        startTime && endTime
          ? moment(calcCurrentTime, "HH:mm:ss").isBetween(startTime, endTime)
          : false;
    } else {
      todayOpenHours = openHours?.Sundays?.hourStart
        ? openHours?.Sundays?.hourStart + " - " + openHours?.Sundays?.hourEnd
        : "Closed";
      let startTime = openHours?.Sundays?.hourStart
        ? moment(openHours?.Sundays?.hourStart, "HH:mm:ss")
        : "";
      let endTime = openHours?.Sundays?.hourEnd
        ? moment(openHours?.Sundays?.hourEnd, "HH:mm:ss")
        : "";
      isOpen =
        startTime && endTime
          ? moment(calcCurrentTime, "HH:mm:ss").isBetween(startTime, endTime)
          : false;
    }
  }
  if (weekDayArary.includes(TomorrowDay)) {
    tomorrowOpenHours =
      openHours?.Weekdays?.hourStart + " - " + openHours?.Weekdays?.hourEnd;
  } else {
    if (TomorrowDay == "Sat") {
      tomorrowOpenHours = openHours?.Saturdays?.hourStart
        ? openHours?.Saturdays?.hourStart +
          " - " +
          openHours?.Saturdays?.hourEnd
        : "Closed";
    } else {
      tomorrowOpenHours = openHours?.Sundays?.hourStart
        ? openHours?.Sundays?.hourStart + " - " + openHours?.Sundays?.hourEnd
        : "Closed";
    }
  }

  return (
    <div>
      {" "}
      <div className="thisMyLocation sToreLocate">
        <div className="storeImgSource">
          <div className="bgOverlay"></div>
          <img
            src={storeData?.facecadeURL}
            style={{ height: "-webkit-fill-available", borderRadius: "5px" }}
          />
        </div>
        <div className="openFrameNow">
          <div className={isOpen ? "openNowBox" : "openNowBox cloeNowBox"}>
            {isOpen ? "Open now" : "Closed"}
          </div>
          <div className="storeBigName">{storeData ? storeData?.name : ""}</div>
          <div className="storeBigLocation">
            {storeData
              ? `${storeData?.address} ${storeData?.addressLine2} ${storeData?.city} ${storeData?.postalCode}`
              : ""}
          </div>
          <div className="storeTimeing">
            Today: {storeData ? todayOpenHours : ""}
            <br />
            Tomorrow: {storeData ? tomorrowOpenHours : ""}
          </div>
          {true ? (
            <div className="" style={{ display: "flex" }}>
              <div className="storeMoreInfo">More Info</div>

              <div
                className="storeMoreInfo map"
                onClick={() => {
                  window.location.replace(
                    `https://www.google.com/maps/search/?api=1&query=${storeData.latitude},${storeData.longitude}`
                  );
                }}
              >
                <img
                  style={{ width: "11px", height: "14px" }}
                  src={appImages.STORE_LOCATION_ICON}
                />
                See on map
              </div>
            </div>
          ) : (
            <div className="storeMoreInfo" onClick={showStoreMoreInfo}>
              {showMore ? "Less Info" : "More Info"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StoreLocation;
