import { useEffect, useState } from "react";
import {
  useCreateNewOrderMutation,
  useGetBoTagDataMutation,
} from "../store/order/OrderApi";
import { GetBoTag, createNewOrder } from "../interfaces/payload";

interface Args {
  onSuccess?: () => void;
  onOrderSuccess?: () => void;
  onOrderError?: () => void;
}
export const useOrder = ({ onSuccess, onOrderSuccess, onOrderError }: Args) => {
  const [error, setError] = useState<string>("");
  const [
    getBoTag,
    { isLoading, isSuccess, isError, data: resData, isUninitialized },
  ] = useGetBoTagDataMutation();

  useEffect(() => {
    if (isLoading) {
      setError("");
      return;
    }

    if (isSuccess && resData?.status === 200 && resData?.data) {
      setError("");
      onSuccess?.();
    } else if (isError) {
      const errMessage = "Incorrect email or password. Try again.";
      setError(errMessage);
    }
  }, [isLoading, isSuccess, isError, resData]);
  const [
    createNewOrder,
    {
      isLoading: orderLoading,
      isSuccess: orderSuccess,
      isError: orderError,
      data,
    },
  ] = useCreateNewOrderMutation();

  useEffect(() => {
    if (orderLoading) {
    } else if (orderSuccess) {
      onOrderSuccess?.();
    } else if (orderError) {
      onOrderError?.();
    }
  }, [orderLoading, orderSuccess, orderError]);

  const handleCreateNewOrder = (payload: createNewOrder) => {
    createNewOrder(payload);
  };
  const handleBoTagData = (payload: GetBoTag) => {
    getBoTag(payload);
  };

  return {
    handleBoTagData,
    isLoading,
    resData,
    handleCreateNewOrder,
    orderLoading,
    isError,
    orderError,
  };
};
