import appImages from "../constants/images";
import "../styles/bottom.css";

const Bottom = () => {
  return (
    <div className="powered-by">
      <img src={appImages.POWERED_BY} height={"54px"} />
    </div>
  );
};

export default Bottom;
