import "../styles/checkout.css";
import CustomerInfo from "../components/CustomerInfo";
import OrderCard from "../components/OrderCard";
import PaymentInfo from "../components/PaymentInfo";
import SubmitPayment from "../components/SubmitPayment";
import { ArrowBack } from "@mui/icons-material";
import { FC, useEffect, useState } from "react";
import { CustomerInfoProps } from "../interfaces/props";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { HANDLE_CARD_PAYMENT } from "../api/Endpoints";
import { userLocation } from "../interfaces/payload";
import { useOrder } from "../hooks/useOrder";
import LoadingScreen from "../components/Loader";
import appImages from "../constants/images";
import Bottom from "../components/Bottom";
import { Navbar } from "../components/Navbar";
interface Iprops {
  storeInfo: any;
  productInfo: any;
  prefrence: string;
  userAddress: userLocation;
  onOrderCreated?: () => void;
  onCancelClick?: () => void;
  getCustomerDetails?: (e: CustomerInfoProps) => void;
}
const CheckoutPanel: FC<Iprops> = ({
  storeInfo,
  prefrence,
  userAddress,
  productInfo,
  onOrderCreated,
  getCustomerDetails,
  onCancelClick,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [firstNameError, setFirstNameError] = useState(true);
  const [lastNameError, setLastNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [cardHolder, setCardHolder] = useState(true);
  const [cardNumber, setCardNumber] = useState(true);

  const [cardExp, setCardExp] = useState(true);
  const [cardCvv, setCardCvv] = useState(true);
  const [loader, setLoader] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(true);
  const [customerDetails, setCustomerdetails] = useState<CustomerInfoProps>({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    cardHolderName: "",
  });
  const { handleCreateNewOrder, orderLoading } = useOrder({
    onOrderSuccess: () => {
      setLoader(false);
      onOrderCreated?.();
    },
    onOrderError: () => {
      setLoader(false);
    },
  });

  function createNewOrder() {
    const _accountID = localStorage.getItem("pid");

    const newOrder = {
      firstName: customerDetails?.firstName,
      lastName: customerDetails?.lastName,
      email: customerDetails?.email,
      mobileNumber: customerDetails?.mobileNumber,

      cardName: cardHolder,
      cardNumber: cardNumber,
      cardExpireDate: cardExp,
      cardCVV: cardCvv,
      accountID: _accountID || undefined,
      items: [
        {
          GTINbarcode: storeInfo?.GTINbarcode || "",
          itemPrice: storeInfo?.price,
          storeID: storeInfo?.storeDetail?._id,
          BOimageID: productInfo?.BOimageID,
          deliveryCost:
            storeInfo?.cf?.Zones?.[0]?.shippingPrice ||
            storeInfo?.cf?.Zones?.[0]?.courierPrice,
          userLocation: userAddress,
          deliveryPreferences: prefrence,
          deliveryOption: storeInfo?.type,
        },
      ],
    };

    handleCreateNewOrder?.(newOrder as any);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      !customerDetails?.firstName ||
      !customerDetails?.lastName ||
      !customerDetails?.email ||
      !customerDetails?.mobileNumber ||
      !customerDetails?.cardHolderName ||
      !customerDetails?.cardNumber ||
      !customerDetails?.cardExpDate ||
      !customerDetails?.cardCVV
    ) {
      if (!customerDetails?.firstName) {
        setFirstNameError(false);
      }
      if (!customerDetails?.lastName) {
        setLastNameError(false);
      }
      if (!customerDetails?.mobileNumber) {
        setMobileNumberError(false);
      }
      if (!customerDetails?.email) {
        setEmailError(false);
      }
      if (!customerDetails?.cardHolderName) {
        setCardHolder(false);
      }
      if (!customerDetails?.cardNumber) {
        setCardNumber(false);
      }
      if (!customerDetails?.cardExpDate) {
        setCardExp(false);
      }
      if (!customerDetails?.cardCVV) {
        setCardCvv(false);
      }
      return;
    }
    getCustomerDetails?.(customerDetails);
    try {
      setLoader(true);
      const body = {
        storeID: storeInfo?.storeDetail?._id,
        totalPrice: storeInfo?.totalPrice,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BOTHEBOT_API_URL}${HANDLE_CARD_PAYMENT}`,

        body
      );
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const cvcElement = elements.getElement("cardCvc");
      const expiryElement = elements.getElement("cardExpiry");

      if (cardElement) {
        const result = await stripe.confirmCardPayment(
          response.data.paymentIntent.client_secret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: customerDetails?.cardHolderName,
              },
            },
          }
        );
        if (result.error) {
          console.log(result.error.message);
        } else {
          if (result.paymentIntent.status === "succeeded") {
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    createNewOrder();
  };

  return (
    <div
      className="showCheckoutContent"
      style={{
        background: "white",
        paddingBottom: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Navbar />
        <h3
          className="relatedTextHead"
          style={{ display: loader || orderLoading ? "none" : "flex" }}
        >
          <div className="navbar-quick-chck">
            <div className="right-side">
              <span
                className="qucikText"
                style={{ display: loader || orderLoading ? "none" : "" }}
              >
                Quick Checkout
              </span>{" "}
            </div>
            <div className="left-side">
              {loader || orderLoading ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img src={appImages.BAG_ICON} />
                  <img src={appImages.LOGOUT_ICON} />
                </div>
              ) : (
                <span
                  className="moreInfoClose makeItRedbg"
                  onClick={onCancelClick}
                >
                  Cancel
                </span>
              )}
            </div>
          </div>
        </h3>
        {loader || orderLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "fixed",
              width: "371px",
              top: "45%",
            }}
          >
            <LoadingScreen />
            <div className="loading-text">Processing your order</div>
          </div>
        ) : (
          <>
            <OrderCard
              storeInfo={storeInfo}
              prefrence={prefrence}
              productInfo={productInfo}
            />
            <CustomerInfo
              setCustomerData={(e) =>
                setCustomerdetails({
                  ...customerDetails,
                  firstName: e?.firstName,
                  lastName: e?.firstName,
                  email: e?.email,
                  mobileNumber: e.mobileNumber,
                })
              }
              isFirst={firstNameError}
              isLast={lastNameError}
              isEmail={emailError}
              isPhone={mobileNumberError}
            />
            <PaymentInfo
              customerCardData={(e: any) =>
                setCustomerdetails({
                  ...customerDetails,
                  cardHolderName: e.cardHolderName,
                  cardExpDate: e.cardExpDate,
                  cardNumber: e.cardNumber,
                  cardCVV: e.cardCVV,
                })
              }
              cardHolder={cardHolder}
              cardCvv={cardCvv}
              cardExp={cardExp}
              cardNumber={cardExp}
            />

            <SubmitPayment
              onSubmit={handleSubmit}
              totalAmount={storeInfo?.totalPrice}
            />
          </>
        )}
      </div>
      {loader || orderLoading ? (
        <div className="bottom-with-loader-c">
          <Bottom />
        </div>
      ) : null}
    </div>
  );
};

export default CheckoutPanel;
