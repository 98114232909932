import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    changeBagCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { changeBagCount } = storeSlice.actions;
export default storeSlice.reducer;
