import { ArrowBack } from "@mui/icons-material";
import "../styles/thankyou.css";
import PriceConverter, { isPickup } from "../helpers/utilHelpers";
import appImages from "../constants/images";
import StoreLocation from "../components/StoreLocation";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Bottom from "../components/Bottom";
interface Iprops {
  customerDetails?: any;

  storeInfo?: any;
}
function ThankYouPanel({ customerDetails, storeInfo }: Iprops) {
  const { firstName, email } = customerDetails;

  let storeDetail: any = [];

  let dummArray = [];
  if (!storeInfo?.length) {
    dummArray.push(storeInfo);
  } else {
    dummArray = storeInfo;
  }
  dummArray.forEach((_v: any, _i: any) => {
    storeDetail.push(_v?.storeInfo);
  });
  const arrayUniqueByKey = [
    ...(new Map(
      storeDetail.map((item: any) => [item?.storeID, item])
    ).values() as any),
  ];
  return (
    <div
      className="showCheckoutContent"
      style={{
        background: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ paddingBottom: "10px" }}>
        <div className="navbar-thankyou">
          <div
            className="right-side"
            onClick={() => {
              window.parent.postMessage("hideButton", "*");
            }}
          >
            <ArrowBack fontSize="small" />
            <span className="locationcloseIcon">Close</span>
          </div>
        </div>

        <div className="thankYouPage">
          <div style={{ padding: "0px 25px" }}>
            <div className="checkIcons">
              <img src={appImages.CHECK_GIF} />
            </div>
            <div className="thankYouMsg">Congratulations, {firstName}!</div>
            <div className="thankSubtext">
              <>
                Your receipt and order confirmation will
                <br /> be sent to your email.
              </>
              <br />
              <span className="thankyouEmail"></span>
            </div>
            <div className="orderTotoalThx">
              <div className="devider"></div>
              <div className="orderTotText">Order Total</div>
            </div>
            <div className="orderTotalNumber">
              {PriceConverter(storeInfo?.totalPrice, "de-DE")} kr
            </div>
            <div className="pickup-message-container">
              <p className="pickup-message">
                {isPickup(storeInfo) ? (
                  <>
                    {" "}
                    Please wait for store confirmation <br /> before going to
                    the store to pick up.
                  </>
                ) : (
                  <>
                    Please wait for store confirmation. You <br /> can check
                    order status and details here:
                  </>
                )}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={"payNowBigBtn active"}>See order details</div>
            </div>
            <div className="orderTotoalThx-store">
              <div className="devider-store"></div>
              <div className="orderTotText-store">
                You bought from the following store
              </div>
            </div>
          </div>
          {/* <div className="youBought">
          {arrayUniqueByKey.length > 1
            ? "You bought from the following (" +
              arrayUniqueByKey.length +
              ") store"
            : "You bought from the following store"}
        </div> */}
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav={false}
            dots={false}
            items={1}
          >
            {/* {arrayUniqueByKey.map((_d, _p) => {

            return ( */}
            <div className="item">
              <StoreLocation
                storeInfo={storeInfo}
                selectedStore={0}
                changeLabel={true}
                key={1}
              />
            </div>
            {/* );
          })} */}
          </OwlCarousel>
        </div>
      </div>
      <div className="bottom-with-loader">
        <Bottom />
      </div>
    </div>
  );
}

export default ThankYouPanel;
