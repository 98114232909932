import { FC } from "react";
import "../styles/submitPayment.css";
interface Iprops {
  onSubmit?: (e: any) => void;
  totalAmount?: string | number;
}
const SubmitPayment: FC<Iprops> = ({ onSubmit, totalAmount }) => {
  return (
    <div className="totalToPayCompund">
      <div className="totalToPay">
        <div className="dashlineTextinside">
          <div className="sperateBorderclass"></div>
          <p className="dashedText">
            <span className="orContinueText">Total to pay</span>
          </p>
        </div>
        <div className="maxiMumAmoutn">
          <div className="finalTotalAmount">{totalAmount} kr</div>
          <div className={"payNowBigBtn active"} onClick={onSubmit}>
            Place order
          </div>
        </div>
        <div className="termsTextcart">
          By clicking "Pay now" I agree to the terms & conditions of
          <br />
          BOtag Nordic AS
        </div>
      </div>
    </div>
  );
};

export default SubmitPayment;
