import "../styles/loaderSmall.css";
const LoaderSmall = () => {
  return (
    <div className="loading-screen-small">
      <div className="loading-spinner-small"></div>
    </div>
  );
};

export default LoaderSmall;
