import { getDistance } from "geolib";
import moment from "moment";

export const deliveryPrefrence = (pref: string, delivery: string) => {
  if (pref === "fastest") {
    if (delivery === "pickup") {
      return "Fastest option";
    } else if (delivery === "courier") {
      return `Fastest ${delivery}`;
    } else {
      return `Fastest ${delivery}`;
    }
  } else {
    if (delivery === "pickup") {
      return "Cheapest option";
    } else if (delivery === "courier") {
      return `Cheapest ${delivery}`;
    } else {
      return `Cheapest ${delivery}`;
    }
  }
};
function isMoment(value: string | moment.Moment): value is moment.Moment {
  return (value as moment.Moment).format !== undefined;
}
export const secondsToDhms = (seconds: string | number) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " day" : " days") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hr" : " hrs") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min" : " mins") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";

  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const sortData = async (
  pref: any,
  data: any,
  lat: number,
  long: number,
  type?: string
) => {
  let cheapest = [...data];
  let ffc = [...data];
  let storeInfo;
  let cheapestDataList;

  if (pref === "cheapest") {
    if (cheapest?.length) {
      const cheapestData = [...cheapest];
      cheapestData.map((_o: any, _i: any) => {
        let distance = getDistance(
          {
            latitude: lat ? lat : 0 || "",
            longitude: long || "",
          },
          {
            latitude: _o?.storeDetail ? _o?.storeDetail?.latitude : 0,
            longitude: _o?.storeDetail ? _o?.storeDetail?.longitude : 0,
          }
        );
        if (!Object.isExtensible(_o)) {
          const extensibleObj = { ..._o };
          extensibleObj.awayFromMtr = distance * 0.9;
          extensibleObj.awayFrom =
            distance < 1000
              ? distance + "m"
              : Math.round(distance / 1000) + "km";

          cheapestData[cheapestData.indexOf(_o)] = extensibleObj;
        } else {
          _o.awayFromMtr = distance;
          _o.awayFrom =
            distance < 1000
              ? distance + "m"
              : Math.round(distance / 1000) + "km";
        }
      });
      if (cheapestData?.length) {
        ffc = cheapestData;
        let d = cheapestData.filter((d: any) => {
          return d?.type === "pickup";
        });

        d.sort((a: any, b: any) => {
          return a.totalPrice - b.totalPrice || a.awayFromMtr - b.awayFromMtr;
        });

        let ff = d.filter((r: any) => {
          return r?.cf;
        });

        cheapestDataList = ff;
        if (type) {
          const data = ff.filter((item) => item?.type === type);
          storeInfo = data[0];
        } else {
          storeInfo = ff[0];
        }
      }
    }
  } else {
    let distanceArray = [];
    if (ffc?.length) {
      const ffcInfo = [...ffc];
      ffcInfo?.map((_o: any, _i: number) => {
        let distance = getDistance(
          { latitude: lat || "", longitude: long || "" },
          {
            latitude: _o?.ffcDetail?.storeLatitude,
            longitude: _o?.ffcDetail?.storeLongitude,
          }
        );

        let calcCurrentTime = "";
        let todayCurrentTime = moment().utcOffset("+02:00");
        calcCurrentTime = todayCurrentTime.format("HH:mm:ss");
        let openHours = _o?.storeDetail?.openHours;
        let weekDayArary = ["Mon", "Tue", "Wed", "Thu", "Fri"];
        let todayDay = moment().utcOffset("+02:00").format("ddd");
        let isOpen, difference;
        if (weekDayArary.includes(todayDay)) {
          let startTime = moment(openHours?.Weekdays?.hourStart, "HH:mm:ss");
          let endTime = moment(openHours?.Weekdays?.hourEnd, "HH:mm:ss");
          isOpen = moment(calcCurrentTime, "HH:mm:ss").isBetween(
            startTime,
            endTime
          );
          difference = startTime.diff(
            moment(calcCurrentTime, "HH:mm:ss"),
            "seconds"
          );
        } else {
          if (todayDay == "Sat") {
            let startTime = openHours?.Saturdays?.hourStart
              ? moment(openHours?.Saturdays?.hourStart, "HH:mm:ss")
              : "";
            let endTime = openHours?.Saturdays?.hourEnd
              ? moment(openHours?.Saturdays?.hourEnd, "HH:mm:ss")
              : "";
            isOpen =
              startTime && endTime
                ? moment(calcCurrentTime, "HH:mm:ss").isBetween(
                    startTime,
                    endTime
                  )
                : false;

            difference =
              startTime && isMoment(startTime)
                ? startTime.diff(
                    (moment(calcCurrentTime, "HH:mm:ss"), "seconds")
                  )
                : 0;
          } else {
            let startTime = openHours?.Sundays?.hourStart
              ? moment(openHours?.Sundays?.hourStart, "HH:mm:ss")
              : "";
            let endTime = openHours?.Sundays?.hourEnd
              ? moment(openHours?.Sundays?.hourEnd, "HH:mm:ss")
              : "";
            isOpen =
              startTime && endTime
                ? moment(calcCurrentTime, "HH:mm:ss").isBetween(
                    startTime,
                    endTime
                  )
                : false;
            difference =
              startTime && isMoment(startTime)
                ? startTime.diff(moment(calcCurrentTime, "HH:mm:ss"), "seconds")
                : 0;
          }
        }

        if (_o?.type == "pickup") {
          if (!Object.isExtensible(_o)) {
            const extensibleObj = { ..._o };
            extensibleObj.awayFromMtr = distance;
            extensibleObj.awayFrom =
              distance < 1000
                ? distance + "m"
                : Math.round(distance / 1000) + "km";
            distanceArray.push(
              distance < 1000
                ? distance + "m"
                : Math.round(distance / 1000) + "km"
            );
            ffcInfo[ffcInfo.indexOf(_o)] = extensibleObj;
          } else {
            _o.awayFromMtr = distance;
            _o.awayFrom =
              distance < 1000
                ? distance + "m"
                : Math.round(distance / 1000) + "km";
            distanceArray.push(
              distance < 1000
                ? distance + "m"
                : Math.round(distance / 1000) + "km"
            );
          }
          if (isOpen) {
            if (!Object.isExtensible(_o)) {
              const extensibleObj = { ..._o };
              extensibleObj.totalTime = distance * 0.9;

              ffcInfo[ffcInfo.indexOf(_o)] = extensibleObj;
            } else {
              _o.totalTime = distance * 0.9;
            }
          } else {
            if (!Object.isExtensible(_o)) {
              const extensibleObj = { ..._o };
              extensibleObj.totalTime =
                distance * 0.9 > difference ? distance * 0.9 : difference;
              ffcInfo[ffcInfo.indexOf(_o)] = extensibleObj;
            } else {
              _o.totalTime =
                distance * 0.9 > difference ? distance * 0.9 : difference;
            }
          }
        } else if (_o?.type == "courier") {
          if (_o?.cf?.Zones?.length) {
            if (!Object.isExtensible(_o)) {
              const extensibleObj = { ..._o };
              extensibleObj.totalTime = _o?.cf?.Zones[0].courierTime;
              ffcInfo[ffcInfo.indexOf(_o)] = extensibleObj;
            } else {
              _o.totalTime = _o?.cf?.Zones[0].courierTime;
            }
          }
        } else if (_o?.type == "shipping") {
          if (_o?.cf) {
            if (_o?.cf.countryFound) {
              if (_o?.cf?.Zones?.length) {
                if (!Object.isExtensible(_o)) {
                  const extensibleObj = { ..._o };
                  extensibleObj.totalTime = _o?.cf?.Zones[0].shippingTime;
                  ffcInfo[ffcInfo.indexOf(_o)] = extensibleObj;
                } else {
                  _o.totalTime = _o?.cf?.Zones[0].shippingTime;
                }
              }
            } else {
              if (typeof _o?.cf?.Zones[1] !== "undefined") {
                if (!Object.isExtensible(_o)) {
                  const extensibleObj = { ..._o };
                  extensibleObj.totalTime = _o?.cf?.Zones[1].shippingTime;
                  ffcInfo[ffcInfo.indexOf(_o)] = extensibleObj;
                } else {
                  _o.totalTime = _o?.cf?.Zones[1].shippingTime;
                }
              }
            }
          }
        }
      });
      ffc = ffcInfo;
      if (ffcInfo?.length) {
        let d = ffcInfo?.filter((_d: any) => {
          if (_d?.type === "pickup") {
            if (localStorage.getItem("isShowAnyway") !== "true") {
              return _d;
            } else {
              return _d;
            }
          } else {
            return _d;
          }
        });
        d.sort(function (a: any, b: any) {
          return a.totalTime - b.totalTime || a.awayFromMtr - b.awayFromMtr;
        });

        let ff = d.filter((r: any) => {
          return r?.cf;
        });

        if (localStorage.getItem("isShowAnyway") !== "true") {
        }

        if (type) {
          const data = ff.filter((item) => item?.type === type);
          storeInfo = data[0];
        } else {
          storeInfo = ff[0];
        }
      } else {
        storeInfo = "";
      }
    }
  }

  const returnData = {
    ffc,
    storeInfo,
    cheapestDataList,
  };
  return returnData;
};
export const isStoreOpen = (storeInfo: any) => {
  if (storeInfo) {
    let isOpen: any;
    let calcCurrentTime = "";
    let todayCurrentTime = moment().utcOffset("+02:00");
    calcCurrentTime = todayCurrentTime.format("HH:mm:ss");
    let openHours = storeInfo?.storeDetail?.openHours;
    let weekDayArary = ["Mon", "Tue", "Wed", "Thu", "Fri"];
    let todayDay = moment().format("ddd");
    if (weekDayArary.includes(todayDay)) {
      let startTime = moment(openHours?.Weekdays?.hourStart, "HH:mm:ss");
      let endTime = moment(openHours?.Weekdays?.hourEnd, "HH:mm:ss");

      isOpen = moment(calcCurrentTime, "HH:mm:ss").isBetween(
        startTime,
        endTime
      );
    } else {
      if (todayDay == "Sat") {
        let startTime = openHours?.Saturdays?.hourStart
          ? moment(openHours?.Saturdays?.hourStart, "HH:mm:ss")
          : "";
        let endTime = openHours?.Saturdays?.hourEnd
          ? moment(openHours?.Saturdays?.hourEnd, "HH:mm:ss")
          : "";
        if (startTime && endTime) {
          isOpen = moment(calcCurrentTime, "HH:mm:ss").isBetween(
            startTime,
            endTime
          );
        } else {
          isOpen = false;
        }
      } else {
        let startTime = openHours?.Sundays?.hourStart
          ? moment(openHours?.Sundays?.hourStart, "HH:mm:ss")
          : "";
        let endTime = openHours?.Sundays?.hourEnd
          ? moment(openHours?.Sundays?.hourEnd, "HH:mm:ss")
          : "";
        if (startTime && endTime) {
          isOpen = moment(calcCurrentTime, "HH:mm:ss").isBetween(
            startTime,
            endTime
          );
        } else {
          isOpen = false;
        }
      }
    }
  }
};
export function getAddressFromCoordinates(lat: number, lng: number) {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_MAP_KEY}`;
  let county: any, city: any, address: any;
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          let county, city, address;

          for (let result of data.results) {
            for (let component of result.address_components) {
              if (component.types.includes("administrative_area_level_2")) {
                county = component.long_name;
              } else if (component.types.includes("locality")) {
                city = component.long_name;
              }
            }
            if (result.formatted_address) {
              address = result.formatted_address;
            }
          }

          if (county || city || address) {
            const resultData = {
              county: county,
              city: city,
              address: address,
              lat: lat,
              long: lng,
            };
            resolve(resultData);
          } else {
            reject("Some information is not available for this location.");
          }
        } else {
          reject(`Error: ${data.status}`);
        }
      })
      .catch((error) => {
        reject(`Error: ${error}`);
      });
  });
}
export default function PriceConverter(amount: any, countryCode: string) {
  let type = "number";
  if (Number.isInteger(amount)) {
    type = "number";
  } else {
    type = "float";
  }
  let calc = parseFloat(amount).toLocaleString(countryCode, {
    minimumFractionDigits: type == "float" ? 2 : 0,
  });
  return calc;
}
export const isPickup = (item = []) => {
  let isPickup;
  if (Array.isArray(item) === true) {
    isPickup = item?.find((item: any) => item?.type === "pickup");
    return isPickup;
  }

  return false;
};
export const getSingleCacheData = async (cacheName: string, url: any) => {
  if (typeof caches === "undefined") return false;
  const cacheStorage = await caches.open(cacheName);
  const cachedResponse = await cacheStorage.match(url);
  if (!cachedResponse || !cachedResponse.ok) {
    return false;
  }
  return cachedResponse.json().then((item) => {
    return item;
  });
};
export const addDataIntoCache = (
  cacheName: string,
  url: any,
  response: any
) => {
  const data = new Response(JSON.stringify(response));
  if ("caches" in window) {
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
};
export const deleteSpecificCache = (cacheName: string) => {
  if ("caches" in window) {
    caches.delete(cacheName).then(function (res) {
      return res;
    });
  }
};
export const priceConverter = (amount: number | any, countryCode: any) => {
  let type = "number";
  if (Number.isInteger(amount)) {
    type = "number";
  } else {
    type = "float";
  }
  let calc = parseFloat(amount).toLocaleString(countryCode, {
    minimumFractionDigits: type == "float" ? 2 : 0,
  });
  return calc;
};

export const totalPrice = (item: any) => {
  let sum = 0;
  item.map((it: any) => {
    sum += it?.storeInfo?.totalPrice;
  });
  return sum;
};
