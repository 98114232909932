import { useEffect, useState } from "react";
import CheckoutPanel from "../panels/CheckoutPanel";
import LocationPanel from "../panels/LocationPanel";
import "../styles/home.css";
import { Coords } from "../interfaces/location";
import StorePanel from "../panels/StorePanel";
import { StorePanelProps } from "../interfaces/props";
import { useProduct } from "../hooks/useProduct";
import { LoadScript } from "@react-google-maps/api";
import ThankYouPanel from "../panels/ThankYouPanel";
import { useParams } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import "../styles/error.css";
import { deleteSpecificCache } from "../helpers/utilHelpers";
const Home = () => {
  const [panel, setPanel] = useState(0);
  const { barcode, brandID } = useParams();
  const [prefrence, setPrefrence] = useState("");
  const [storeInfo, setStoreInfo] = useState<any>();
  const [productInfo, setProductInfo] = useState<any>();
  const [customerDetails, setCustomerdetails] = useState<any>();
  const [isPageLoad, setIsPageLoad] = useState(false);

  const { handleProduct, data, isLoading, isError } = useProduct({
    onSuccess: () => setProductInfo(data?.result),
  });
  const [coords, setCoords] = useState<StorePanelProps>({
    lat: "",
    long: "",
    address: "",
    city: "",
    country: "",
  });
  useEffect(() => {
    if (!isPageLoad) {
      deleteSpecificCache("myBag");
    }
    setIsPageLoad(true);
  }, [brandID]);
  const panels = [
    <LocationPanel handleCoords={(c) => setCoords(c as any)} />,
    <StorePanel
      productInfo={productInfo}
      data={coords}
      onChangeClick={() => {
        setCoords({
          lat: "",
          long: "",
          address: "",
          city: "",
          country: "",
        });
        setPanel(0);
      }}
      onBuyNowClick={() => {
        setPanel(2);
      }}
      getStoreInfo={(e: any) => setStoreInfo(e)}
      setPrefrence={(e: string) => setPrefrence(e)}
    />,
    <CheckoutPanel
      storeInfo={{ ...storeInfo, customerAdress: coords.address }}
      prefrence={prefrence}
      userAddress={{
        country: coords.country || "",
        latitude: coords.lat,
        longitude: coords.long,
      }}
      productInfo={productInfo}
      onOrderCreated={() => {
        setPanel(panel + 1);
      }}
      getCustomerDetails={(e: any) => setCustomerdetails(e)}
      onCancelClick={() => setPanel(panel - 1)}
    />,
    <ThankYouPanel storeInfo={storeInfo} customerDetails={customerDetails} />,
  ];
  useEffect(() => {
    const payload = {
      brandID: brandID,
      barcode: barcode,
    };

    if (!data && !isLoading) {
      handleProduct(payload);
    }
  }, []);

  useEffect(() => {
    if (coords?.lat && coords?.long && coords?.address) {
      setPanel(1);
    }
  }, [coords]);
  if (!brandID || !barcode || isError) {
    if (!brandID && !barcode) {
      return (
        <div className="main" style={{ display: "flex" }}>
          <div className="rest-screen"></div>
          <div className="showCheckoutContent-error">
            <Navbar />
            <div style={{ textAlign: "center", color: "#FF0000" }}>
              LabalBrandID and GTIN are not found
            </div>
          </div>
        </div>
      );
    }
    if (!brandID) {
      return (
        <div className="main" style={{ display: "flex" }}>
          <div className="rest-screen"></div>
          <div className="showCheckoutContent-error">
            <Navbar />
            <div style={{ textAlign: "center", color: "#FF0000" }}>
              LabalBrandID is not found
            </div>
          </div>
        </div>
      );
    }
    if (!barcode) {
      return (
        <div className="main" style={{ display: "flex" }}>
          <div className="rest-screen"></div>
          <div
            className="showCheckoutContent-error"
            style={{ backgroundColor: "white" }}
          >
            <Navbar />
            <div style={{ textAlign: "center", color: "#FF0000" }}>
              GTIN is not found
            </div>
          </div>
        </div>
      );
    }
    if (isError) {
      return (
        <div className="main" style={{ display: "flex" }}>
          <div className="rest-screen"></div>
          <div
            className="showCheckoutContent-error"
            style={{ backgroundColor: "white" }}
          >
            <Navbar />
            <div style={{ textAlign: "center", color: "#FF0000" }}>
              Connection failed
            </div>
          </div>
        </div>
      );
    }
  }
  return (
    <LoadScript
      libraries={["places", "maps", "marker"]}
      googleMapsApiKey={process.env.REACT_APP_MAP_KEY as string}
    >
      <div className="main">
        <div className="rest-screen"></div>
        {panels[panel]}
      </div>
    </LoadScript>
  );
};

export default Home;
