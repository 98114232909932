import { FC, useEffect, useState } from "react";
import appImages from "../constants/images";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";
import "../styles/customerInfo.css";
import "../styles/paymentInfo.css";
import { StripeCardNumberElement } from "@stripe/stripe-js";
interface Iprops {
  customerCardData: (e: any) => void;
  cardNumber: boolean;
  cardExp: boolean;
  cardCvv: boolean;
  cardHolder: boolean;
}
const PaymentInfo: FC<Iprops> = ({
  customerCardData,
  cardCvv,
  cardExp,
  cardNumber,
  cardHolder,
}) => {
  const [cardHolderName, setCardHolderName] = useState("");
  const [customerCardNumber, setCustomerCardNumber] = useState(false);
  const [cardExpDate, setCardExpDate] = useState(false);
  const [cardCVV, setCardCVV] = useState(false);
  const elements = useElements();

  const handleCardHolderName = (e: any) => {
    setCardHolderName(e.target.value);
  };
  const inputStyle = {
    color: "black",
    fontWeight: "500",
    fontSize: "14px",
    fontSmoothing: "antialiased",
    border: "0.5px solid red",
    ":-webkit-autofill": {
      color: "#fce883",
    },
    "::placeholder": {
      color: "#848484",
    },
  };
  useEffect(() => {
    if (cardHolderName && customerCardNumber && cardExpDate && cardCVV) {
      customerCardData({
        cardHolderName: cardHolderName,
        cardNumber: customerCardNumber,
        cardCVV: cardCVV,
        cardExpDate: cardExpDate,
      });
    }
  }, [cardHolderName, customerCardNumber, cardExpDate, cardCVV]);

  return (
    <div className="paymentComponentDiv">
      <h4 className="subHeadingEighteen">
        <span className="headingLines">Payment Method</span>
      </h4>
      <div className="paymentCards">
        <div className="paymentMethod">
          <img src={appImages.APPLY_BTN} />
        </div>
        <div className="paymentMethod">
          <img src={appImages.STRIPE_BTN} />
        </div>
        <div className="paymentMethod">
          <img src={appImages.KLARNA_BTN} />
        </div>
      </div>
      <div>
        <div className="dashlineTextinside">
          <div className="sperateBorderclass"></div>
          <p className="dashedText-cards">
            <span className="orContinueText">or continue with card below</span>
          </p>
        </div>
      </div>
      <div className="paywithCards">
        <div className="customrow" style={{ paddingBottom: "20px" }}>
          <div className="fullDiv">
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 14,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: "50%",
                  color: "red",
                  display: !cardHolder && !cardHolderName ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <input
                type="text"
                className="form-control"
                style={{
                  border:
                    !cardHolder && !cardHolderName ? "0.5px solid red" : "",
                }}
                placeholder="Name on card"
                value={cardHolderName}
                onChange={handleCardHolderName}
              />
            </div>
          </div>
          <div className="fullDiv cardInside">
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 14,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",
                  display:
                    !cardNumber && !customerCardNumber ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <i className="fa-light fa-credit-card"></i>

              <CardNumberElement
                onChange={() => setCustomerCardNumber(true)}
                className={
                  !cardNumber && !customerCardNumber
                    ? "form-control StripeElement--invalid"
                    : "form-control stripe-cards"
                }
                options={{ style: { base: inputStyle } }}
              />
            </div>
          </div>
          <div className="fullDiv tripleElement">
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 14,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",
                  display: !cardExp && !cardExpDate ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <CardExpiryElement
                onChange={() => setCardExpDate(true)}
                className={
                  !cardExp && !cardExpDate
                    ? "form-control StripeElement--invalid"
                    : "form-control stripe-cards"
                }
                options={{ style: { base: inputStyle } }}
              />
            </div>
            <div className="form-group" style={{ position: "relative" }}>
              <label
                style={{
                  position: "absolute",
                  right: 14,
                  fontSize: "8px",
                  fontWeight: "400",
                  top: 8,
                  color: "red",
                  display: !cardCvv && !cardCVV ? "block" : "none",
                }}
              >
                Field is required
              </label>
              <CardCvcElement
                onChange={() => setCardCVV(true)}
                className={
                  !cardCvv && !cardCVV
                    ? "form-control StripeElement--invalid"
                    : "form-control stripe-cards"
                }
                options={{ style: { base: inputStyle } }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
