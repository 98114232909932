import "../styles/storePanel.css";
import { Navbar } from "../components/Navbar";
import appImages from "../constants/images";
import Map from "../components/Map";
import { useEffect, useState } from "react";
import { StoreOrderCard } from "../components/StoreOrderCard";
import { StorePanelProps } from "../interfaces/props";
import { useOrder } from "../hooks/useOrder";
import LoadingScreen from "../components/Loader";
import {
  addDataIntoCache,
  getSingleCacheData,
  sortData,
} from "../helpers/utilHelpers";
import Bottom from "../components/Bottom";
import { useParams } from "react-router-dom";
import LoaderSmall from "../components/LoaderSmall";

interface Iprops {
  data: StorePanelProps;
  onChangeClick?: () => void;
  onBuyNowClick?: () => void;
  getStoreInfo: (e: any) => void;
  setPrefrence: (e: string) => void;
  productInfo: any;
}

function StorePanel({
  data,
  onChangeClick,
  onBuyNowClick,
  getStoreInfo,
  setPrefrence,
  productInfo,
}: Iprops) {
  const { barcode, brandID } = useParams();

  const { address, city, country, lat, long } = data;
  const [methodType, setMethodType] = useState("pickup");
  const [storeInfo, setStoreInfo] = useState<any>();

  const [ffcInfo, setFFCInfo] = useState<any>([]);
  const [available, setAvailable] = useState<boolean>();
  const [deliveryPrefrence, setDeliveryPrefrence] = useState("fastest");
  const { handleBoTagData, isLoading, resData } = useOrder({
    onSuccess: () => {
      sortData(deliveryPrefrence, resData.data, lat as any, long as any).then(
        (res) => {
          setStoreInfo(res.storeInfo);
          setFFCInfo(res.ffc);
          setMethodType(res.storeInfo.type);
          setAvailable(true);
        }
      );
    },
  });

  const handleShippingFun = (type: string) => {
    setMethodType(type);

    if (deliveryPrefrence === "cheapest") {
      let d = ffcInfo.filter((d: any) => d.type === type);
      d.sort((a: any, b: any) => {
        return a?.totalPrice - b?.totalPrice;
      });

      if (!d.length) {
        setAvailable(false);
      } else {
        setStoreInfo(d[0]);
        setAvailable(true);
      }
    } else {
      let d = ffcInfo.filter((d: any) => d.type === type);
      d.sort((a: any, b: any) => {
        return a?.awayFromMtr - b?.awayFromMtr;
      });

      if (!d.length) {
        setAvailable(false);
      } else {
        setStoreInfo(d[0]);
        setAvailable(true);
      }
    }
  };
  useEffect(() => {
    if (storeInfo) {
      getStoreInfo(storeInfo);
      setPrefrence(deliveryPrefrence);
    }
  }, [storeInfo]);
  useEffect(() => {
    getSearchedData(
      barcode || "",
      brandID || "",
      deliveryPrefrence,
      methodType as any,
      city,
      country
    );
  }, []);
  const getSearchedData = async (
    GTINbarcode: string | number,
    brandID: string,
    type: string,
    shippingMethod: string,
    userCurrentCity: string,
    userCurrentCountry: string
  ) => {
    let formData = {
      GTINbarcode: GTINbarcode,
      labelBrand: brandID,
      shippingMethod: shippingMethod,
      userCities: userCurrentCity ? userCurrentCity : "",
      userCountry: userCurrentCountry ? userCurrentCountry : "",
      latitude: lat,
      longitude: long,
    };

    handleBoTagData({ ...formData });
  };

  const handlePrefrence = (type: string) => {
    setDeliveryPrefrence(type);

    getSearchedData(
      barcode || "",
      brandID || "",
      type,
      methodType as any,
      city,
      country
    );
  };

  const addToBag = async () => {
    let addToBagJson = {
      storeInfo: { ...storeInfo, customerAdress: address },
      productInfo: productInfo,
      // brandInfo: data?.brandInfo?.name,
      // color: colorName,
      // size: size,
      // quantity: quantity,
      // methodType: methodType,
      // deliveryAmt: otherAmt,
      sortingType: deliveryPrefrence,
      // lbl: lbl,
      address: address,
    };
    let getData = await getSingleCacheData("myBag", window.location.origin);

    if (getData?.length) {
      // bagCountFunction(getData?.length + 1);
      getData.push(addToBagJson);
      addDataIntoCache("myBag", window.location.origin, getData);
    } else {
      // bagCountFunction(1);
      addDataIntoCache("myBag", window.location.origin, [addToBagJson]);
    }
    // updateBagFunc();
  };
  return (
    <div
      className="showCheckoutContent"
      style={{
        background: "white",
        overflow: isLoading ? "hidden" : "",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <Navbar />
        {/* {isLoading ? <LoadingScreen /> : null} */}
        <div className="location-header">
          <div className="heading">Your location</div>
          <div className="address">
            <div className="user-address">{address}</div>
            <div className="change-address" onClick={onChangeClick}>
              <img
                src={appImages.CHANGE_ICON}
                className="change-icon"
                style={{ width: "10px", height: "9px" }}
              />
              <span className="change-text">Change</span>
            </div>
          </div>
        </div>
        <Map
          allStoreData={ffcInfo}
          coords={{ lat: lat, lng: long }}
          storeInfo={storeInfo}
          storeCoords={{
            lat: storeInfo?.storeDetail?.latitude || 0,
            lng: storeInfo?.storeDetail?.longitude || 0,
          }}
        />
        <div className="delivery-slider">
          <div
            className={
              deliveryPrefrence === "fastest"
                ? "delivery-options pick-up active"
                : "delivery-options pick-up"
            }
            onClick={() => handlePrefrence("fastest")}
          >
            Fastest
          </div>
          <div
            className={
              deliveryPrefrence === "cheapest"
                ? "delivery-options courier active"
                : "delivery-options courier"
            }
            onClick={() => handlePrefrence("cheapest")}
          >
            Cheapest
          </div>
        </div>
        <div className="delivery-slider">
          <div
            className={
              methodType === "pickup"
                ? "delivery-options pick-up active"
                : "delivery-options pick-up"
            }
            onClick={() => handleShippingFun("pickup")}
          >
            Pick up
          </div>
          <div
            className={
              methodType === "courier"
                ? "delivery-options courier active"
                : "delivery-options courier"
            }
            onClick={() => handleShippingFun("courier")}
          >
            Courier
          </div>
          <div
            className={
              methodType === "shipping"
                ? "delivery-options shipping active"
                : "delivery-options shipping"
            }
            onClick={() => handleShippingFun("shipping")}
          >
            Shipping
          </div>
        </div>
        <div className="border-divider"></div>
        {isLoading ? (
          <div className="loader-mini-container" style={{ marginTop: "14px" }}>
            <LoaderSmall />
            <div className="search-loader-text">Searching stores for you</div>
          </div>
        ) : available ? (
          <div style={{ visibility: isLoading ? "hidden" : "visible" }}>
            <StoreOrderCard
              methodType={methodType}
              prefrence={deliveryPrefrence}
              storeInfo={storeInfo}
              onBuyNowClick={onBuyNowClick}
              addToBag={addToBag}
            />
          </div>
        ) : null}
      </div>
      <Bottom />
    </div>
  );
}

export default StorePanel;
