import { useState } from "react";
import "../styles/locationPanel.css";
import { Link } from "react-router-dom";

import {
  StandaloneSearchBox,
  LoadScript,
  useLoadScript,
  useJsApiLoader,
  StandaloneSearchBoxProps,
} from "@react-google-maps/api";
import { CityCountry, Coords } from "../interfaces/location";
import appImages from "../constants/images";
import { ArrowBack } from "@mui/icons-material";
import Bottom from "../components/Bottom";
import { getAddressFromCoordinates } from "../helpers/utilHelpers";
import { Navbar } from "../components/Navbar";
interface Iprops {
  handleCoords?: (e?: Coords) => void;
}
function LocationPanel({ handleCoords }: Iprops) {
  const [coords, setCoords] = useState({
    lat: "",
    long: "",
  });

  const [enableGenericLoc, setEnableGenericLoc] = useState(false);
  const [isOpenGeneric, setIsOpenGeneric] = useState(false);
  const [inputRef, setInputRef] = useState<any>();

  const opneLocationPopup = () => {
    // setShowLocationDiv(true);
    setIsOpenGeneric(true);
  };

  const enableLocation = () => {
    navigator.geolocation.getCurrentPosition(success, error, options);
  };
  const success = (position: any) => {
    const data = getAddressFromCoordinates(
      position.coords.latitude,
      position.coords.longitude
    );

    data
      .then((result) => {
        handleCoords?.(result as Coords);
      })
      .catch((err) => console.log(err));

    setCoords({
      lat: position.coords.latitude,
      long: position.coords.longitude,
    });
    // handleCoords?.({
    //   lat: position.coords.latitude,
    //   long: position.coords.longitude,
    // });
  };
  const error = (e: any) => {
    opneLocationPopup();
    console.log(e);
    setEnableGenericLoc(true);
  };
  const options = {
    timeout: 3000,
    enableHighAccuracy: true,
  };

  const setGenericLocFunc = () => {
    setEnableGenericLoc(true);
  };
  const handlePlaceChanged = () => {
    const [place] = inputRef?.getPlaces();
    if (place) {
      const country = place?.address_components.find((component: any) =>
        component?.types?.includes("country")
      )?.long_name;

      const city = place.address_components.find(
        (component: any) =>
          component?.types.includes("locality") ||
          component?.types.includes("administrative_area_level_1")
      )?.long_name;

      const coordinates = {
        lat: place?.geometry?.location.lat(),
        long: place?.geometry?.location.lng(),
        address: place?.formatted_address,
        country: country,
        city: city,
      };

      setCoords(coordinates as any);

      handleCoords?.(coordinates as any);
    }
  };
  return (
    <div
      className="showCheckoutContent"
      style={{
        background: "white",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <div>
        {/* <div className="navbar">
          <div
            className="right-side"
            onClick={() => {
              window.parent.postMessage("hideButton", "*");
            }}
          >
            <ArrowBack fontSize="small" />
            <span className="locationcloseIcon">Close</span>
          </div>
          <div className="left-side">
            <img className="bag-icon" src={appImages.BAG_ICON} />
            <img src={appImages.LOGOUT_ICON} />
          </div>
        </div> */}
        <Navbar />
        <div className="bothebot-pup locatonPopDev">
          <div className="locationNameDiv">Share location</div>
          <div className="location-info-text">
            {enableGenericLoc ? (
              <>
                <span className="hourglassIcon">
                  <img src={appImages.SEARCH_LOCATION_ICOM} />

                  <StandaloneSearchBox
                    onLoad={(ref) => setInputRef(ref)}
                    onPlacesChanged={handlePlaceChanged}
                  >
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search"
                    />
                  </StandaloneSearchBox>
                </span>
              </>
            ) : (
              <>
                BOtag Technology use location
                <br /> information to show stores near you
              </>
            )}
          </div>
          <div className="movingLocationIcon">
            <img
              style={{
                maxWidth: "120px",
                maxHeight: "137px",
                margin: "15px 0px",
              }}
              src={appImages.LOCATION_ICON}
            />
          </div>
          {!enableGenericLoc ? (
            <>
              <div className="location-btn-area newLook">
                <Link
                  to="#"
                  className="locateBtns usegenricLocation"
                  onClick={setGenericLocFunc}
                >
                  Insert location
                </Link>
                <Link
                  to="#"
                  className="locateBtns shareUselocarion"
                  onClick={enableLocation}
                >
                  Share location
                </Link>
              </div>
              <div className="terms-conditions">
                By sharing location, you accept the terms and conditions.
              </div>
            </>
          ) : (
            <>
              <div className="deliveryDistaneText">
                {isOpenGeneric ? (
                  <span className="locationnotFound">
                    Location could not be detected.
                  </span>
                ) : (
                  ""
                )}
                Delivery distances, time and prices will be
                <br />
                calculated considering the inserted location.
                {!isOpenGeneric ? (
                  <>
                    <Link to="#" onClick={enableLocation}>
                      Share my location instead
                    </Link>
                    <div className="terms-conditions">
                      By sharing location, you accept the terms and conditions.
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Bottom />
    </div>
  );
}

export default LocationPanel;
