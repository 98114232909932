import "../styles/storeOrder.css";
import appImages from "../constants/images";
import {
  addDataIntoCache,
  deliveryPrefrence,
  getSingleCacheData,
  secondsToDhms,
} from "../helpers/utilHelpers";
import { useEffect, useState } from "react";

import moment from "moment";

interface Iprops {
  methodType: string;
  prefrence: string;
  storeInfo: any;
  onBuyNowClick?: () => void;
  addToBag?: () => void;
}
export const StoreOrderCard = ({
  methodType,
  prefrence,
  storeInfo,
  onBuyNowClick,
  addToBag,
}: Iprops) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (storeInfo) {
      let calcCurrentTime = "";
      let todayCurrentTime = moment().utcOffset("+02:00");
      calcCurrentTime = todayCurrentTime.format("HH:mm:ss");
      let openHours = storeInfo?.storeDetail?.openHours;
      let weekDayArary = ["Mon", "Tue", "Wed", "Thu", "Fri"];
      let todayDay = moment().format("ddd");
      if (weekDayArary.includes(todayDay)) {
        let startTime = moment(openHours?.Weekdays?.hourStart, "HH:mm:ss");
        let endTime = moment(openHours?.Weekdays?.hourEnd, "HH:mm:ss");
        setIsOpen(
          moment(calcCurrentTime, "HH:mm:ss").isBetween(startTime, endTime)
        );
      } else {
        if (todayDay == "Sat") {
          let startTime = openHours?.Saturdays?.hourStart
            ? moment(openHours?.Saturdays?.hourStart, "HH:mm:ss")
            : "";
          let endTime = openHours?.Saturdays?.hourEnd
            ? moment(openHours?.Saturdays?.hourEnd, "HH:mm:ss")
            : "";
          if (startTime && endTime) {
            setIsOpen(
              moment(calcCurrentTime, "HH:mm:ss").isBetween(startTime, endTime)
            );
          } else {
            setIsOpen(false);
          }
        } else {
          let startTime = openHours?.Sundays?.hourStart
            ? moment(openHours?.Sundays?.hourStart, "HH:mm:ss")
            : "";
          let endTime = openHours?.Sundays?.hourEnd
            ? moment(openHours?.Sundays?.hourEnd, "HH:mm:ss")
            : "";
          if (startTime && endTime) {
            setIsOpen(
              moment(calcCurrentTime, "HH:mm:ss").isBetween(startTime, endTime)
            );
          } else {
            setIsOpen(false);
          }
        }
      }
    }
  }, [storeInfo]);

  return (
    <div className="store-order">
      <div className="prefrence-container">
        <img
          className="active-icon checkout"
          src={
            prefrence === "fastest"
              ? appImages.SOLID_FAST
              : appImages.SOLID_CHEAP
          }
        />
        <span className="prefrence-text">
          {deliveryPrefrence(prefrence, methodType)}
        </span>
      </div>
      <div className="item-name">
        <div className="productMainName" title="Sirup Cap">
          {storeInfo?.storeDetail?.name}
        </div>
        <div className="itemPriceFinal">kr {storeInfo?.price}</div>
      </div>
      <div className="prodBrandName">
        <div>{storeInfo?.storeDetail?.address}</div>
        <div
          className="delivery-price-container"
          style={{
            visibility: storeInfo?.type === "pickup" ? "hidden" : "visible",
          }}
        >
          {storeInfo?.type === "courier"
            ? `+ Delivery kr ${storeInfo?.deliveryCharges || ""}`
            : `+ Delivery kr ${
                storeInfo?.cf?.countryFound
                  ? storeInfo?.cf?.Zones?.[0].shippingPrice
                  : storeInfo?.cf?.Zones?.[1].shippingPrice
              }`}
        </div>
      </div>
      <div className="open-status-and-btns">
        <div className="open-status">
          <div className="stock-status">
            <div
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: storeInfo?.stock < 1 ? "red" : "#69C23F",
                borderRadius: "50%",
              }}
            />
            <div className="stock-text">
              {storeInfo?.stock < 1 ? "Out of stock" : "In stock"}
            </div>
          </div>
          <div className="open-status">{isOpen ? "Open now" : "Closed"} </div>
        </div>
        <div className="btns">
          <button className="add-to-bag" onClick={addToBag}>
            Add to bag
          </button>
          <button className="buy-now" onClick={onBuyNowClick}>
            Buy now
          </button>
        </div>
      </div>
      <div className="delivery-details">
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img
            style={{
              height: "12px",
            }}
            src={
              methodType === "pickup"
                ? appImages.PICKUP_ICON
                : methodType === "shipping"
                ? appImages.SHIPPING_ICON
                : appImages.COURIER_ICON
            }
          />
          <div className="method-text-container">
            {methodType.charAt(0).toUpperCase() + methodType.slice(1)}
          </div>
        </div>
        {methodType !== "pickup" ? (
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {methodType === "shipping" ? (
              <img
                style={{
                  height: "9px",
                }}
                // src={appImages.POSTNORD}
                src={storeInfo?.cf?.logoURL}
              />
            ) : (
              <img
                style={{
                  height: "14px",
                }}
                // src={appImages.WOLT}
                src={storeInfo?.cf?.logoURL}
              />
            )}
          </div>
        ) : null}
        <div className="distance" style={{ flex: 1 }}>
          {methodType === "pickup"
            ? storeInfo?.awayFrom + " from you"
            : methodType === "courier" && storeInfo?.cf !== "yes"
            ? "Approx. " + storeInfo?.estimatedTime || ""
            : // secondsToDhms(storeInfo?.cf?.Zones?.[0]?.courierTime)
              secondsToDhms(storeInfo?.cf?.Zones?.[0]?.shippingTime)}
        </div>
      </div>
      <div className="border-divider-bottom"></div>
    </div>
  );
};
