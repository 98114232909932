import { BOIMAGE_API } from "../../api/Endpoints";
import { boTheBotApi } from "../baseApi";

export const OrderApiEndpoints = boTheBotApi.injectEndpoints({
  endpoints: (builder) => ({
    getBoImagePublisherData: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_PLUGIN_API_URL}${BOIMAGE_API}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const { useGetBoImagePublisherDataMutation } = OrderApiEndpoints;
